import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import Head from 'Components/core/Head';
import DataForm from 'Components/adept/medical/DataForm';

export default class PageAdeptAdeptData extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-adept-adept-data"
                styles={require('./styles')}
            >
                <Head title="Nowy wywiad medyczny" />
                <DataForm
                    location={location}
                    history={history}
                />
            </StyledComponent>
        );
    }
}
