import { connect } from 'react-redux';
import Component from './component';

import {
    list as listMedicalQuestions,
} from 'Redux/modules/adept/medicalQuestions/actions';
import {
    create as createMedicalCard,
} from 'Redux/modules/adept/medicalCards/actions';
import {
    getProfile,
} from 'Redux/modules/user/actions';

export default connect(
    state => ({
        medicalQuestions: state.adeptMedicalQuestions.list,
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            listMedicalQuestions: dispatch(listMedicalQuestions),
            createMedicalCard: dispatch(createMedicalCard),
            getProfile: dispatch(getProfile),
        },
    })
)(Component);