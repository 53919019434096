import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { ADEPT_DATA } from 'Consts/routes';
import { DATE_UNICODE_FORMAT } from 'Consts/date';

import { sortByKey } from 'Utils/array';
import { toApiFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ButtonsGroup from 'Components/forms/ButtonsGroup';
import Form from 'Components/forms/Form';
import Input from 'Components/forms/Input';
import DatePicker from 'Components/forms/DatePicker';
import Button from 'Components/layout/Button';

export default class AdeptMedicalDataForm extends Component {
    static propTypes = {
        // Redux props
        actions: PropTypes.shape({
            listMedicalQuestions: PropTypes.func.isRequired,
            createMedicalCard: PropTypes.func.isRequired,
            getProfile: PropTypes.func.isRequired,
        }),
        medicalQuestions: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
            meta: PropTypes.object,
            error: PropTypes.bool.isRequired,
        }).isRequired,
        profile: PropTypes.object.isRequired,
    }
    static defaultProps = {};

    state = {
        isEnd: false,
        isSaved: false,
        isBasicDataCompleted: false, 

        currentQuestionId: null,
        values: {},
        categories: [],
        questions: [],

        isPending: false,
        globalError: null,
        errors: {},

        weight: null,
        height: null,
        birthdate: null,
    }

    componentDidMount = () => {
        const { actions } = this.props;

        actions.listMedicalQuestions();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { medicalQuestions } = this.props;
        const { isEnd } = this.state;

        if (!prevProps.medicalQuestions.isLoaded && medicalQuestions.isLoaded) {
            this.questionsToState(medicalQuestions.elements);
        }

        if (!prevState.isEnd && isEnd) {
            this.onSubmit();
        }
    }

    questionsToState = questions => {
        questions.map(question => {
            if (question.category) {
                this.setState(prevState => {
                    const existingCategory = prevState.categories.find(stateCategory => stateCategory.id === question.category.id);
                    if (existingCategory) {
                        return prevState;
                    }

                    return {
                        ...prevState,
                        categories: sortByKey(
                            [
                                ...prevState.categories,
                                question.category,
                            ],
                            'order'
                        ),
                    };
                });
            }
        });

        this.setState(prevState => {
            const parsedQuestions = questions.map(question => {
                return {
                    id: question.id,
                    slug: question.slug,
                    title: question.adeptTitle || question.title,
                    subtitle: question.adeptSubtitle || null,
                    type: question.type,
                    subQuestions: question.subQuestions.map(subQuestion => ({
                        id: subQuestion.id,
                        slug: subQuestion.slug,
                        title: subQuestion.adeptTitle || subQuestion.title,
                        subtitle: subQuestion.adeptSubtitle || null,
                        type: subQuestion.type,
                    })),
                    category: question.category,
                    order: question.order,
                    isMainQuestion: true,
                };
            });

            let parsedValues = {};
            // questions.map(question => {
            //     parsedValues[question.id] = {
            //         value: null,
            //         additionalData: null,
            //     };
            // });

            return {
                ...prevState,
                questions: sortByKey(parsedQuestions, 'order'),
                values: parsedValues,
            };
        });
    }

    getCurrentQuestionIndex = () => {
        const { currentQuestionId, questions } = this.state;

        let questionIndex = 0;
        if (currentQuestionId) {
            questionIndex = questions
                .findIndex(({ id }) => id == currentQuestionId);
        }

        return questionIndex;
    }

    getCurrentCategoryIndex = () => {
        const { questions, categories } = this.state;

        const currentQuestionIndex = this.getCurrentQuestionIndex();
        const currentQuestion = questions[currentQuestionIndex];
        if (!currentQuestion || !currentQuestion.category) return -1;

        const categoryIndex = categories.findIndex(category => category.id === currentQuestion.category.id);
        return categoryIndex;
    }

    renderQuestion = question => {
        const { slug, title, subtitle } = question;

        return (
            <div
                className={classnames(
                    'question',
                    `question-${slug}`
                )}
            >
                <h2 className="question-title">{title}</h2>
                {subtitle && <h3 className="question-subtitle">{subtitle}</h3>}

                <div className="question-form">
                    {this.renderQuestionForm(question)}
                </div>
            </div>
        );
    }

    renderQuestionForm = ({ id, slug, type, title, component, subQuestions }, parentId = null) => {
        const { values } = this.state;

        let value = values[parentId || id] || null;
        if (parentId) {
            if (value && value.additionalData) {
                value = value.additionalData[id] || null;
            } else {
                value = null;
            }
        } else {
            value = value && value.value || null;
        }

        switch (type) {
            case 'STRING':
                return (
                    <div
                        key={slug}
                        className={classnames(
                            'question',
                            'question-type-string',
                            `question-form-${slug}`
                        )}
                    >
                        <Input
                            name={slug}
                            value={value}
                            label={title}
                            placeholder={title}
                            onChange={({ value }) => this.onAnswer(id, value, parentId)}
                        />
                    </div>
                );
            case 'BOOLEAN':
                return (
                    <div
                        key={slug}
                        className={classnames(
                            'question',
                            'question-type-boolean',
                            `question-form-${slug}`
                        )}
                    >
                        <div className="question-form">
                            <ButtonsGroup
                                name={slug}
                                value={value}
                                style="faPrimary"
                                size="large"
                                options={[{
                                    label: 'Tak',
                                    value: true,
                                }, {
                                    label: 'Nie',
                                    value: false,
                                }]}
                                onChange={({ value }) => this.onAnswer(id, value, parentId)}
                            />
                        </div>

                        {value && subQuestions.length > 0 && (
                            <div className="subquestion-form">
                                {subQuestions.map(subQuestion => this.renderQuestionForm(subQuestion, id))}

                                <div className="subquestion-footer">
                                    <Button
                                        style="faPrimary"
                                        size="large"
                                        onClick={() => this.onNextQuestion()}
                                    >
                                        Następne pytanie
                                    </Button>
                                </div>
                            </div>
                        )}

                    </div>
                );
            case 'component':
                return (
                    <div
                        key={slug}
                        className={classnames(
                            'question',
                            'question-type-component',
                            `question-form-${slug}`
                        )}
                    >
                        {component}
                    </div>
                );
        }
    }

    renderCurrentQuestion = () => {
        const { questions } = this.state;
        const questionIndex = this.getCurrentQuestionIndex();
        const question = questions[questionIndex];
        if (!question) return null;

        return this.renderQuestion(question);
    }

    renderCategoryResult = ({ id, name }, index) => {
        const { values, questions } = this.state;
        const categoryIndex = this.getCurrentCategoryIndex();

        const categoryQuestions = questions.filter(question => question.category && question.category.id === id);
        const categoryAnswers = Object.keys(values).filter(valueKey => categoryQuestions.find(categoryQuestion => categoryQuestion.id === valueKey));

        return (
            <div
                key={name}
                className={classnames({
                    'question-result': true,
                    active: categoryIndex === index,
                    resolved: categoryIndex > index,
                })}
            >
                <div className="question-index">
                    <span className="question-number">
                        {index + 1}
                    </span>
                </div>
                <div className="question-result-content">
                    <h4 className="question-title">
                        {name}
                    </h4>
                    <h5 className="question-value">
                        {categoryAnswers.length} / {categoryQuestions.length}
                    </h5>
                </div>
            </div>
        );
    }

    onNextQuestion = () => {
        const { questions } = this.state;

        const questionIndex = this.getCurrentQuestionIndex();
        const nextQuestionIndex = questionIndex + 1;
        const nextQuestion = questions[nextQuestionIndex];

        let isEnd = false;
        if (questions.length - 1 === nextQuestionIndex) {
            isEnd = true;
        }

        this.setState({
            currentQuestionId: nextQuestion && nextQuestion.id || null,
            isEnd,
        });
    }

    onAnswer = (id, value, parentId) => {
        const { questions } = this.state;

        const questionIndex = this.getCurrentQuestionIndex();
        const question = questions[questionIndex];

        this.setState(prevState => {
            let previousValue = prevState.values[parentId || id];

            let answer = {
                value: null,
                additionalData: null,
                ...previousValue,
            };

            if (parentId) {
                answer = {
                    ...answer,
                    value: prevState.values[parentId].value,
                    additionalData: {
                        ...prevState.values[parentId].additionalData,
                        [id]: value,
                    },
                };
            } else {
                answer.value = value;
                answer = {
                    ...answer,
                    value,
                };
            }

            return {
                values: {
                    ...prevState.values,
                    [parentId || id]: answer,
                },
            };
        });

        if (question.isMainQuestion && (value === false || (value === true && question.subQuestions.length === 0))) {
            this.onNextQuestion();
        }
    }

    onSubmit = () => {
        const { actions, profile } = this.props;
        const { values, weight, height, birthdate } = this.state;

        return actions.createMedicalCard({
            answers: values,
            height,
            weight,
            birthdate: toApiFormat(birthdate, 'date'),
            withUserData: Boolean(!profile.userData),
        })
            .then(response => {
                actions.getProfile();
                this.setState({
                    isSaved: true,
                })
                toast('Dane zostały zapisane');
            })
            .catch(err => toast('Wystąpił błąd, spróbuj ponownie'));
    }

    onSubmitUserData = (weight, height, birthdate) => {
        if (!weight || !height || !birthdate) {
            toast.error('Podaj wszystkie wymagane pola');
        } else {
            this.setState({ isBasicDataCompleted: true })
        }
    }

    render() {
        const { medicalQuestions, profile } = this.props;
        const { categories, isEnd, weight, height, birthdate, isBasicDataCompleted, isSaved } = this.state;

        if (medicalQuestions.isLoading || !medicalQuestions.isLoaded) {
            return <Spinner />;
        }

        return (
            <StyledComponent
                className={classnames({
                    'component-public-online-survey': true,
                })}
                styles={require('./styles')}
            >
                {!isBasicDataCompleted && !profile.userData
                    ? (
                        <div className="basic-data">
                            <div className="basic-data-headline">Podaj dane podstawowe</div>
                            <Input
                                name="weight"
                                value={weight}
                                label="Waga"
                                type="number"
                                styleVersion={1}
                                placeholder="Waga"
                                onChange={({ value }) => this.setState({ weight: value })}
                                required={true}
                            />
                            <Input
                                name="height"
                                value={height}
                                label="Wzrost"
                                type="number"
                                styleVersion={1}
                                placeholder="Wzrost"
                                onChange={({ value }) => this.setState({ height: value })}
                                required={true}
                            />
                            <div className="basic-data-headline"><span className="required-sign">* </span>Podaj date urodzenia</div>
                            <DatePicker 
                                name="birthdate"
                                value={moment(birthdate).toDate()}
                                styleVersion={1}
                                datePickerProps={{
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd' || 'dd-MM-yyyy',
                                    showMonthDropdown: true,
                                    showYearDropdown: true,
                                }}
                                onChange={(date) => this.setState({
                                    birthdate: date.value,
                                })}
                            />
                            <Button
                                style="hollow"
                                size="large"
                                onClick={() => this.onSubmitUserData(weight, height, birthdate)}
                            >
                                Następne pytania
                            </Button>
                        </div>
                    )
                    : (
                        <>
                            {isEnd
                                ? (
                                    <div className="end">
                                        <h1 className="end-headline">
                                            Dziękujemy za wypełnienie wywiadu
                                        </h1>
                                        <Link to={ADEPT_DATA.path}>
                                            <Button
                                                style="hollow"
                                                size="large"
                                                disabled={!isSaved}
                                            >
                                                Powrót do panelu
                                            </Button>
                                        </Link>
                                    </div>
                                )
                                : (
                                    <div className="cols">
                                        <div className="col-question">
                                            {this.renderCurrentQuestion()}
                                        </div>
                                        <div className="col-results">
                                            <div className="results">
                                                {categories.map((category, index) => this.renderCategoryResult(category, index))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </>
                    )
                }
            </StyledComponent>
        );
    }
}